<template>
  <v-expansion-panel :style="styles.app">
    <v-expansion-panel-header class="font-weight-bold text-h5 primary--text">
      <slot name="header" />
      <template v-slot:actions>
        <v-icon color="primary">
          $expand
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot name="content" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';

export default {
  name: 'BaseExpansionPanel',
  mixins: [AppStyleMixin]
};
</script>
