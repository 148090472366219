<template>
  <index-presenter :state-helps="stateHelps" />
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import IndexPresenter from '@/components/pages/help/index/IndexPresenter';

export default {
  name: 'IndexContainer',
  components: { IndexPresenter },
  mixins: [AppMethodsMixin],
  data() {
    return {
      stateHelps: {
        about: false,
        howReceive: false,
        howOrder: false
      }
    };
  },
  mounted() {
    this.showLoadingOverlay(async () => {});
  }
};
</script>
