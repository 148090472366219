<template>
  <v-expansion-panels
    accordion
    flat
    focusable
    multiple
  >
    <slot name="expansion-panels" />
  </v-expansion-panels>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';

export default {
  name: 'BaseExpansionPanels',
  mixins: [AppStyleMixin]
};
</script>
