<template>
  <div>
    <v-container class="mb-6 mt-10" style="max-width: 1000px">
      <div class="text-start mb-5">ヘルプ</div>
      <v-card flat :style="styles.app">
        <base-expansion-panels>
          <template #expansion-panels>
            <base-expansion-panel class="outset mb-6 py-3 px-2">
              <template #header>
                Q.&emsp;<span class="default--text">クエストとは？</span>
              </template>
              <template #content>
                <div class="d-flex flex-row mb-4">
                  <div class="font-weight-bold text-h5 primary--text">A.&emsp;</div>
                  <div class="pt-1">
                    <p class="red--text text--lighten-1">
                      <span class="font-weight-bold">個人のスキルアップやモチベーションの向上を目的とした「社内副業」のことです。</span>
                    </p>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      style="border: 1px solid #FA9F00; height: 100%;"
                    >
                      <v-card-title class="primary--text subtitle-1 font-weight-bold pb-0">
                        Point 1
                      </v-card-title>
                      <v-card-title class="default--text font-weight-bold">
                        <v-icon color="primary">mdi-shield-search</v-icon>
                        &nbsp;社内活動や仕事を探す
                      </v-card-title>
                      <v-card-text>
                        画面上部のタブ「クエストを探す」より、興味がある仕事を探しましょう！
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      style="border: 1px solid #FA9F00; height: 100%;"
                      class="height-100"
                    >
                      <v-card-title class="primary--text subtitle-1 font-weight-bold pb-0">
                        Point 2
                      </v-card-title>
                      <v-card-title class="default--text font-weight-bold">
                        <v-icon color="primary">mdi-shield-plus</v-icon>
                        &nbsp;仕事を依頼する
                      </v-card-title>
                      <v-card-text>
                        画面上部のタブ「クエストを依頼する」より、仕事を依頼しましょう！
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      style="border: 1px solid #FA9F00; height: 100%;"
                    >
                      <v-card-title class="primary--text subtitle-1 font-weight-bold pb-0">
                        Point 3
                      </v-card-title>
                      <v-card-title class="default--text font-weight-bold">
                        <v-icon color="primary">mdi-hand-heart</v-icon>
                        &nbsp;ありがとうを贈る
                      </v-card-title>
                      <v-card-text>
                        クエスト完了後はお互いに"ありがとう"を！<br />
                        感謝を循環させてより良い雰囲気を作りましょう！
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      style="height: 100%;"
                    >
                      <v-card-title class="primary--text subtitle-1 font-weight-bold pb-0">
                        Point 4
                      </v-card-title>
                      <v-card-title class="default--text font-weight-bold">
                        <v-icon color="primary">mdi-alpha-p-circle</v-icon>
                        &nbsp;ポイントを交換する
                      </v-card-title>
                      <v-card
                        flat
                        style="position: absolute; width: 100%; height: 100%; top: 0; opacity: 0.8;"
                        color="blue-grey lighten-1"
                      >
                        <v-card-text class="white--text font-weight-bold text-h4 text-center d-flex align-center justify-center" style="height: 100%;">
                          COMING SOON
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </base-expansion-panel>

            <base-expansion-panel class="outset mb-6 py-3 px-2">
              <template #header>
                Q.&emsp;<span class="default--text">ポイントが付与されるタイミングは？</span>
              </template>
              <template #content>
                <div class="d-flex flex-row">
                  <div class="font-weight-bold text-h5 primary--text">A.&emsp;</div>
                  <div class="pt-1">
                    <p class="red--text text--lighten-1">
                      <span class="font-weight-bold">「発注成立時」と「クエスト完了時」です。</span><br />
                    </p>
                    <p class="default--text">
                      クエストは、受注者だけでなく発注者もポイントを受け取ることができます。<br />
                      それぞれの付与対象者と還元率は下記の通りです。
                      <v-simple-table :style="styles.app">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">タイミング</th>
                              <th class="text-left">付与対象者</th>
                              <th class="text-left">還元率</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>発注成立時</td>
                              <td>発注者</td>
                              <td>クエスト報酬額の&emsp;&emsp;５％</td>
                            </tr>
                            <tr>
                              <td rowspan="2">クエスト完了時</td>
                              <td>発注者</td>
                              <td>クエスト報酬額の&emsp;&emsp;５％</td>
                            </tr>
                            <!-- rowspanでなぜか下罫線が消えるので、style記述で対応 -->
                            <tr>
                              <td style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">受注者</td>
                              <td style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">クエスト報酬額の１００％</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </p>
                  </div>
                </div>
              </template>
            </base-expansion-panel>

            <base-expansion-panel class="outset mb-6 py-3 px-2">
              <template #header>
                Q.&emsp;<span class="default--text">１ポイントの価値は？</span>
              </template>
              <template #content>
                <div class="d-flex flex-row">
                  <div class="font-weight-bold text-h5 primary--text">A.&emsp;</div>
                  <div class="pt-1">
                    <p class="red--text text--lighten-1">
                      <span class="font-weight-bold">１ポイント＝１円相当です。</span><br />
                    </p>
                    <p class="red--text text--lighten-1 caption">
                      ※ 今後、ポイントレートは変動する可能性があります。
                    </p>
                  </div>
                </div>
              </template>
            </base-expansion-panel>
          </template>
        </base-expansion-panels>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseExpansionPanel from '@/components/atoms/BaseExpansionPanel';
import BaseExpansionPanels from '@/components/atoms/BaseExpansionPanels.vue';

export default {
  name: 'IndexPresenter',
  components: { BaseExpansionPanel, BaseExpansionPanels },
  mixins: [AppStyleMixin],
  props: {
    stateHelps: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
// v-simple-tableの行hover時の背景色変更を無効にする
table > tbody > tr:hover {
  background: none !important;
}
</style>
